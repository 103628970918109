import React, { useEffect } from "react";
import { MDBCard, MDBCol, MDBContainer, MDBRow, MDBView } from "mdbreact";
import Sidebar from "../components/Sidebar";
import { useAuth } from "../../core/hooks/useAuth";
import Skeleton from "react-loading-skeleton";
import MobileBanner from "../../tests/presentation/components/MobileBanner";
import PlanList from "../components/PlanList";
import ErrorComponent from "../components/ErrorComponent";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import Quote, { QuoteLink } from "../components/Quote";
import { Quote as QuoteEntity } from "../../domain/entity/Quote";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";
import { ReactComponent as DentalVisionIcon } from "../../assets/dental_vision.svg";
import { ReactComponent as LatitudeIcon } from "../../assets/latitude.svg";

import {
  addNowText,
  findADentistText,
  salesCenterAgencyId,
  utmCampaignMemberPortal,
  utmMediumOrganic,
  utmSourceCrossSellDv,
  viewBrochureText,
  visionProvidersText,
  dentalAndVisionQuoteTitle,
  dentalAndVisionEnrollDisclaimerText,
  dentalAndVisionlearnMoreAboutBenefitsText,
  plansStartingFrom,
  planDetails,
  latitudeQuoteTitle,
  utmSourceCrossSellLatitude,
} from "core/constants/strings";
import {
  dentalAndVisionProductUrl,
  dentalDoctorUrl,
  dentalMembershipSummary,
  dentalPlanBrochureUrl,
  latitudePlanDetailsUrl,
  latitudeProductUrl,
  visionDoctorUrl,
} from "core/constants/endpoints";
import * as queryString from "query-string";
import { Application } from "domain/entity/Application";
import { User } from "domain/entity/User";
import AflacAds from "presentation/components/AflacAds";
import AflacDisclaimer from "presentation/components/AflacDisclaimer";
import AttestationModal from "presentation/components/AttestationModal";

const getLatitudeLinks = (application: Application | null) =>
  [
    {
      text: planDetails,
      url: `${latitudePlanDetailsUrl}?${queryString.stringify({
        sku: "L1I",
        zip: application?.zip,
      })}`,
    },
  ] as Array<QuoteLink>;

const dentalAndVisionLinks: Array<QuoteLink> = [
  { text: viewBrochureText, url: dentalPlanBrochureUrl },
  { text: findADentistText, url: dentalDoctorUrl },
  { text: visionProvidersText, url: visionDoctorUrl },
];

const buildLatitudePageUrl = (
  application: Application | null,
  user: User | null
) =>
  `${latitudeProductUrl}?${queryString.stringify({
    agent_id:
      application?.agencyId === salesCenterAgencyId
        ? application?.agencyId
        : application?.agentId,
    zip: application?.zip,
    first_name: application?.primaryMember?.firstName,
    last_name: application?.primaryMember?.lastName,
    dob: application?.primaryMember?.dob,
    gender: application?.primaryMember?.gender,
    email: application?.email,
    phone: user?.phone,
    address: user?.address,
    city: user?.city,
    middle_initial: user?.middleInitial,
    utm_campaign: utmCampaignMemberPortal,
    utm_source: utmSourceCrossSellLatitude,
    utm_medium: utmMediumOrganic,
  })}`;

const buildDentalVisionPageUrl = (application: Application | null) =>
  `${dentalAndVisionProductUrl}?${queryString.stringify({
    agent_id:
      application?.agencyId === salesCenterAgencyId
        ? application?.agencyId
        : application?.agentId,
    zip: application?.zip,
    email: application?.email,
    dob: application?.primaryMember?.dob,
    gender: application?.primaryMember?.gender,
    first_name: application?.primaryMember?.firstName,
    utm_campaign: utmCampaignMemberPortal,
    utm_source: utmSourceCrossSellDv,
    utm_medium: utmMediumOrganic,
  })}`;

const getQuotesProps = (
  application: Application | null,
  user: User | null
) => ({
  //Latitude
  12: {
    title: latitudeQuoteTitle,
    icon: LatitudeIcon,
    ctaURL: buildLatitudePageUrl(application, user),
    ctaDescriptionText: plansStartingFrom,
    ctaButtonText: addNowText,
    links: getLatitudeLinks(application),
  },
  //Dental
  19: {
    title: dentalAndVisionQuoteTitle,
    icon: DentalVisionIcon,
    enrollDisclaimerText: dentalAndVisionEnrollDisclaimerText,
    learnMoreAboutBenefitsText: dentalAndVisionlearnMoreAboutBenefitsText,
    learnMoreAboutBenefitsURL: dentalMembershipSummary,
    links: dentalAndVisionLinks,
    ctaURL: buildDentalVisionPageUrl(application),
    ctaDescriptionText: plansStartingFrom,
    ctaButtonText: addNowText,
  },
  //Vision
  5: {},
});

const DashboardPage = () => {
  const auth = useAuth();

  const { sendDataToGTM } = useGTM();

  useEffect(() => {
    if (!auth.gettingApplication && !auth.gettingQuote) {
      if (auth.quotes) {
        sendDataToGTM({
          event: "quote",
          value: "success",
        });
      }
    }
  }, [auth.gettingApplication, auth.gettingQuote]);

  useEffect(() => {
    window.executeWhenFinishToRender = window.executeWhenFinishToRender || [];
    if (!auth.gettingApplication) {
      if (auth.application) {
        sendDataToGTM({
          event: "application",
          value: "success",
          state: auth.application.state,
          carrierId: auth.application.carrierId,
        });
      }
      executeWhenFinishToRender();
    }
  }, [auth.gettingApplication]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildQuotes = () => {
    if (auth?.quotes) {
      const quotesProps = getQuotesProps(auth.application, auth.user);
      return (
        <>
          {auth.quotes.map((quote: QuoteEntity, index: number) => {
            const props = quotesProps[quote.productId];
            return (
              <Quote
                key={index}
                icon={props.icon}
                title={props.title}
                enrollDisclaimerText={props.enrollDisclaimerText}
                learnMoreAboutBenefitsURL={props.learnMoreAboutBenefitsURL}
                learnMoreAboutBenefitsText={props.learnMoreAboutBenefitsText}
                planName={quote?.planName}
                price={quote?.premium?.toFixed(2)}
                ctaDescriptionText={props.ctaDescriptionText}
                ctaButtonText={props.ctaButtonText}
                ctaURL={props.ctaURL}
                links={props.links}
              />
            );
          })}
        </>
      );
    }

    if (auth.gettingApplication || auth.gettingQuote) {
      return <Skeleton height="4.56rem" className="mb-3" />;
    }

    return <></>;
  };

  return (
    <MDBView className="full">
      <MDBContainer fluid className="h-100">
        <MDBRow className="h-100 align-content-start bg-alabaster">
          <Sidebar className="d-flex" />
          <MDBCol className="py-3 px-2 px-md-3">
            {!auth.errorGettingApplication ? (
              <>
                <h1 className="h2-responsive font-weight-semi-bold text-center mb-3">
                  {!auth.gettingApplication ? (
                    auth.application?.packageName
                  ) : (
                    <Skeleton height="2.56rem" width="50rem" />
                  )}
                </h1>
                <PlanList />
                {buildQuotes()}
                <AflacAds />
              </>
            ) : (
              <ErrorComponent onTryAgain={() => auth.getApplication()} />
            )}
            <MDBCard>
              <MobileBanner />
            </MDBCard>
            <AflacDisclaimer />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <AttestationModal />
    </MDBView>
  );
};

export default DashboardPage;
