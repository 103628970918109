import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBBtnGroup,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBView,
} from "mdbreact";
import Sidebar from "../components/Sidebar";
import Documents from "../components/Documents";
import { ReactComponent as MedicalIcon } from "../../assets/medical.svg";
import { ReactComponent as DentalIcon } from "../../assets/dental.svg";
import { ReactComponent as VisionIcon } from "../../assets/vision.svg";
import { ReactComponent as LatitudeIcon } from "../../assets/latitude.svg";
import Skeleton from "react-loading-skeleton";
import { useDocument } from "../../core/hooks/useDocument";
import { Document } from "../../domain/entity/Document";
import {
  documentsGeneratingText,
  downloadDocumentText,
  errorDownloadingText,
  noDocumentsText,
  okText,
} from "../../core/constants/strings";
import AlertModal from "../components/AlertModal";
import useToggle from "react-use-toggle";
import useGTM from "@elgorditosalsero/react-gtm-hook";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";
import AttestationModal from "presentation/components/AttestationModal";

const DocumentsPage = () => {
  const { sendDataToGTM } = useGTM();
  const [selected, setSelected] = useState("current");
  const [openItem, setOpenItem] = useState("medical");
  const [isErrorDownloadingModalOpen, setErrorDownloadingModalOpen] = useToggle(
    false
  );
  const document = useDocument();
  const documents = document.documents
    ?.filter((it) => it.status?.toLocaleLowerCase() === selected)
    ?.reduce(
      (unique: Document[], item: Document) =>
        unique
          .map((it) => it.policyId + "-" + it.productType)
          .includes(item.policyId + "-" + item.productType)
          ? unique
          : [...unique, item],
      []
    );

  function toggleOpen(collapseID) {
    setOpenItem(openItem !== collapseID ? collapseID : "");
  }

  useEffect(() => {
    if (!document.gettingDocuments) {
      if (document.documents) {
        sendDataToGTM({ event: "documents", value: "success" });
      }
      executeWhenFinishToRender();
    }
  }, [document.gettingDocuments]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MDBView className="full">
      <MDBContainer fluid className="h-100">
        <MDBRow className="h-100 align-content-start bg-alabaster">
          <Sidebar className="d-none d-md-flex" />
          <MDBCol className="py-3">
            <h1 className="h2-responsive font-weight-semi-bold text-center mb-3">
              Your documents
            </h1>
            <MDBBtnGroup className="mb-4 btn-block btn-rounded">
              <MDBBtn
                color="primary"
                className={`${
                  selected === "past" ? "btn-primary" : "btn-outline-primary"
                } col-4 p-1 btn-md text-capitalize font-weight-semi-bold`}
                id="past-documents"
                onClick={() => setSelected("past")}
              >
                Past
              </MDBBtn>
              <MDBBtn
                color="primary"
                className={`${
                  selected === "current" ? "btn-primary" : "btn-outline-primary"
                } col-4 p-1 btn-md text-capitalize font-weight-semi-bold`}
                id="current-documents"
                onClick={() => setSelected("current")}
              >
                Current
              </MDBBtn>
              <MDBBtn
                color="primary"
                className={`${
                  selected === "upcoming"
                    ? "btn-primary"
                    : "btn-outline-primary"
                } col-4 p-1 btn-md text-capitalize font-weight-semi-bold`}
                id="upcoming-documents"
                onClick={() => setSelected("upcoming")}
              >
                Upcoming
              </MDBBtn>
            </MDBBtnGroup>
            {!document.gettingDocuments ? (
              (documents?.length || 0) > 0 ? (
                <>
                  {documents?.map((it) => (
                    <Documents
                      key={`${it.policyId?.toString()}_${it.productType}_${
                        it.status
                      }`}
                      appId={it.policyId?.toString()}
                      expirationDate={it.expirationDate}
                      effectiveDate={it.effectiveDate}
                      status={it.status}
                      icon={
                        it.productType === "MEDICAL" ? (
                          <MedicalIcon />
                        ) : it.productType === "DENTAL" ? (
                          <DentalIcon />
                        ) : it.productType === "VISION" ? (
                          <VisionIcon />
                        ) : (
                          <LatitudeIcon />
                        )
                      }
                      productType={`${it.productType?.toLocaleLowerCase()}`}
                      planId={`${it.planId}`}
                      id={`${it.policyId?.toString()}_${it.productType}_${
                        it.status
                      }`}
                      isOpen={openItem}
                      documents={document.documents?.filter(
                        (doc) =>
                          doc.status?.toLocaleLowerCase() === selected &&
                          doc.policyId === it.policyId &&
                          doc.productType === it.productType
                      )}
                      onToggleOpen={() =>
                        toggleOpen(
                          `${it.policyId?.toString()}_${it.productType}_${
                            it.status
                          }`
                        )
                      }
                      onDownloadError={setErrorDownloadingModalOpen}
                    />
                  ))}
                  <p className="silver-text">
                    **Date reflects expiration if all required premiums are
                    paid.
                  </p>
                </>
              ) : (
                <p className="py-3 text-center">
                  {document.documentsPending
                    ? documentsGeneratingText
                    : noDocumentsText}
                </p>
              )
            ) : (
              <>
                <Skeleton height="4.56rem" className="mb-3" />
                <Skeleton height="4.56rem" className="mb-3" />
                <Skeleton height="4.56rem" className="mb-3" />
                <Skeleton height="4.56rem" className="mb-3" />
              </>
            )}
            <AlertModal
              isOpen={isErrorDownloadingModalOpen}
              title={downloadDocumentText}
              body={errorDownloadingText}
              positive={okText}
              onPositive={setErrorDownloadingModalOpen}
              onDismiss={setErrorDownloadingModalOpen}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <AttestationModal />
    </MDBView>
  );
};

export default DocumentsPage;
