import React, { useEffect } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBView } from "mdbreact";
import Sidebar from "../components/Sidebar";
import helpImage from "../../assets/help.png";
import pointHealthImage from "../../assets/point_health.png";
import {
  customerServiceText,
  emailText,
  helpPhoneText,
  insuranceBenefitAdministratorsText,
  needHelpTitleText,
  pointHealthText,
  pointHealthMessage,
  pointHealthPhoneNumberText,
  supportText,
  pointHealthWidgetSubtitle,
  anchorFixedBenefitText,
  shortTermText,
} from "../../core/constants/strings";
import {
  helpEmailUrl,
  helpPhoneUrl,
  pointHealhSupportUrl,
  insuranceAdministratorsUrl,
  insuranceAdminsitratorsEmail,
} from "../../core/constants/endpoints";
import { useContactInformation } from "../../core/hooks/useContactInformation";
import executeWhenFinishToRender from "../../core/functions/executeWhenFinishToRender";
import HelpWidget from "presentation/components/HelpWidget";
import { useAuth } from "core/hooks/useAuth";
import AttestationModal from "presentation/components/AttestationModal";

const HelpPage = () => {
  const auth = useAuth();
  const contactInformation = useContactInformation();

  useEffect(() => {
    if (!contactInformation.gettingContactInformation) {
      executeWhenFinishToRender();
    }
  }, [contactInformation.gettingContactInformation]);

  return (
    <MDBView className="full">
      <MDBContainer fluid className="h-100">
        <MDBRow className="h-100 align-content-start bg-alabaster">
          <Sidebar className="d-none d-md-flex" />
          <MDBCol className="py-3">
            <h1 className="h2-responsive font-weight-semi-bold text-center mb-3 tk-bree-serif">
              {needHelpTitleText}
            </h1>
            <HelpWidget
              title={insuranceBenefitAdministratorsText}
              description={customerServiceText}
              image={helpImage}
              leftButtonTitle={emailText}
              rightButtonTitle={
                contactInformation.contactInformation?.phone || helpPhoneText
              }
              leftHref={
                !contactInformation.gettingContactInformation &&
                contactInformation.contactInformation?.email
                  ? helpEmailUrl.replace(
                      "{email}",
                      contactInformation.contactInformation.email.toLowerCase()
                    )
                  : insuranceAdminsitratorsEmail
              }
              rightHref={
                !contactInformation.gettingContactInformation &&
                contactInformation.contactInformation?.phone
                  ? helpPhoneUrl.replace(
                      "{phone}",
                      contactInformation.contactInformation.phone.replace(
                        /[^\w\s]/gi,
                        ""
                      )
                    )
                  : insuranceAdministratorsUrl
              }
            />
            <br />
            {auth.application?.isPointHealthFooterAvailable && (
              <HelpWidget
                title={pointHealthText}
                description={pointHealthWidgetSubtitle.replace(
                  "{medicalPlan}",
                  auth.application?.type === "FBM"
                    ? anchorFixedBenefitText
                    : shortTermText
                )}
                image={pointHealthImage}
                leftButtonTitle={supportText}
                rightButtonTitle={pointHealthPhoneNumberText}
                leftHref={pointHealhSupportUrl}
                rightHref={helpPhoneUrl.replace(
                  "{phone}",
                  pointHealthPhoneNumberText.replace(/[^\w\s]/gi, "")
                )}
              />
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <AttestationModal />
    </MDBView>
  );
};

export default HelpPage;
