import React from "react";
import {
  MDBCol,
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBModal,
  MDBCloseIcon,
  MDBModalBody,
} from "mdbreact";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import {
  accountSettingsText,
  cancelText,
  coverageInformationText,
  documentsText,
  helpPhoneText,
  helpText,
  logoutMessageText,
  logoutText,
  needHelpText,
  welcomeBackText,
} from "../../core/constants/strings";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import {
  helpPhoneUrl,
  insuranceAdministratorsUrl,
} from "../../core/constants/endpoints";
import { useAuth } from "../../core/hooks/useAuth";
import { useRouter } from "../../core/hooks/useRouter";
import useToggle from "react-use-toggle";
import AlertModal from "./AlertModal";
import { useContactInformation } from "../../core/hooks/useContactInformation";
import bannerStyle from "../scss/banner.module.scss";

const Header = () => {
  const auth = useAuth();
  const router = useRouter();
  const contactInformation = useContactInformation();
  const [collapsed, setCollapsed] = useToggle(false);
  const [isLogoutModalOpen, setLogoutModalOpen] = useToggle(false);
  const [isForumModalOpen, setForumModalOpen] = useToggle(false);

  function logout() {
    auth.logout();
    setCollapsed();
    router.replace("/login");
  }

  function openLogoutModal(e) {
    e.preventDefault();
    setLogoutModalOpen();
  }

  function openForumInformationModal(e) {
    e.preventDefault();
    setForumModalOpen();
  }

  /**
   * Checks whether a date is between two dates
   * @param from - The start date
   * @param to - The end date
   * @param check - The date to be checked
   */
  function dateBetween(from: Date, to: Date, check: Date) {
    return check >= from && check <= to;
  }

  // This can be set to false after the Indiana banner period ends
  const forumBannerEnabled = dateBetween(
    new Date("2024-10-21"),
    new Date("2024-11-04"),
    new Date()
  );
  // URL to the Zoom webinar for CLIC
  const forumClicURL =
    "https://healthcare.zoom.us/webinar/register/WN_by2qQxZASKSN983xZm5mTA";
  // URL to the Zoom webinar for NRIC
  const forumNricURL =
    "https://healthcare.zoom.us/webinar/register/WN_TLv7KVT0ReOUIbYrntjxYA";

  function shouldDisplayForumInformationBanner(): Boolean {
    var application = auth.application;
    if (
      forumBannerEnabled &&
      application?.state &&
      application.state === "IN"
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <MDBNavbar
        color="white"
        light
        expand="md"
        scrolling
        className="p-0 z-depth-0 border-bottom d-block"
        id="navbar"
      >
        {shouldDisplayForumInformationBanner() && (
          <div id={bannerStyle["temp-forum-banner"]}>
            <div className={bannerStyle["temp-popup"]}>
              <h3 className={bannerStyle["temp-popup-content"]}>
                <span className={bannerStyle["temp-popup-content--high"]}>
                  Register for the Annual Indiana Public Forum Meeting on Short
                  Term Medical Plan Performance. <br /> For more information
                </span>
                <a
                  href="#"
                  onClick={openForumInformationModal}
                  className={bannerStyle["cro_forum--link"]}
                >
                  {" "}
                  click here.
                </a>
              </h3>
            </div>
          </div>
        )}
        <MDBContainer className="p-3" fluid>
          <MDBNavbarToggler
            onClick={setCollapsed}
            className={`mr-auto ${
              router.pathname === "/login" ||
              router.pathname === "/forgot-password"
                ? "invisible"
                : ""
            } ${collapsed ? "collapsed" : ""}`}
          />
          <MDBNavbarBrand
            href="/"
            className="py-0 font-weight-bold mr-auto ml-auto ml-md-0"
          >
            <Logo className="my-2" style={{ width: "25rem", height: "3rem" }} />
          </MDBNavbarBrand>
          <MDBNavbarNav right>
            {!contactInformation.gettingContactInformation && (
              <MDBNavItem>
                <a
                  href={
                    contactInformation.contactInformation
                      ? helpPhoneUrl.replace(
                          "{phone}",
                          contactInformation.contactInformation.phone?.replace(
                            /[^\w\s]/gi,
                            ""
                          )
                        )
                      : insuranceAdministratorsUrl
                  }
                  className="text-reset"
                  id="contact-call"
                >
                  <MDBRow className="m-0 align-items-center">
                    <MDBCol size="auto" className="p-0 pr-md-2">
                      <Phone style={{ width: "1.5rem", height: "1.5rem" }} />
                    </MDBCol>
                    <MDBCol size="auto" className="p-0 d-none d-md-block">
                      <small>{needHelpText}</small>
                      <p className="m-0">
                        <strong className="font-weight-bold">
                          {contactInformation.contactInformation
                            ? contactInformation.contactInformation.phone
                            : helpPhoneText}
                        </strong>
                      </p>
                    </MDBCol>
                  </MDBRow>
                </a>
              </MDBNavItem>
            )}
          </MDBNavbarNav>
        </MDBContainer>
        {auth.user && (
          <>
            <MDBNavbar
              color="white"
              light
              expand="md"
              className="w-100 z-depth-0 p-0"
            >
              <MDBCollapse
                id="navbarCollapse"
                className="d-md-none"
                isOpen={collapsed}
                navbar
              >
                <MDBNavbarNav className="d-md-none p-0" left>
                  <MDBNavItem className="border-bottom border-top">
                    <MDBNavLink
                      to="/account-settings"
                      className="px-3 py-4 font-weight-semi-bold"
                      onClick={setCollapsed}
                    >
                      <span onClick={setCollapsed}>
                        <MDBIcon icon="cog" className="ml-1 mr-3" />
                        {accountSettingsText}
                      </span>
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink
                      to="/login"
                      className="px-3 py-4 font-weight-semi-bold"
                      onClick={openLogoutModal}
                    >
                      <MDBIcon icon="sign-out-alt" className="ml-1 mr-3" />
                      {logoutText}
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
            <MDBNavbar
              color="primary-color"
              dark
              expand="xs"
              className="w-100 align-items-end z-depth-0 px-0 px-md-3 pt-2 pb-0"
            >
              <MDBNavbarBrand className="col-3 d-none d-md-block mb-2 px-0">
                <p className="mb-0 light-blue-text font-weight-normal">
                  Member Portal
                </p>
                <h5 className="h5-responsive mb-0">
                  <strong className="white-text font-weight-bold">
                    {welcomeBackText}
                  </strong>
                </h5>
              </MDBNavbarBrand>
              <MDBNavbarNav className="w-100">
                <MDBNavItem
                  className="nav-item-tab col"
                  active={router.pathname === "/"}
                >
                  <MDBNavLink to="/" link id="dashboard">
                    {coverageInformationText}
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem
                  className="nav-item-tab col"
                  active={router.pathname === "/documents"}
                >
                  <MDBNavLink to="/documents" id="documents">
                    {documentsText}
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem
                  className="nav-item-tab col"
                  active={router.pathname === "/help"}
                >
                  <MDBNavLink to="/help" id="help">
                    {helpText}
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBNavbar>
          </>
        )}
      </MDBNavbar>
      {collapsed && (
        <div
          id="sidenav-overlay"
          style={{ backgroundColor: "transparent" }}
          onClick={setCollapsed}
        />
      )}
      <MDBModal
        isOpen={isForumModalOpen}
        toggle={setForumModalOpen}
        inline={false}
        noClickableBodyWithoutBackdrop={false}
        overflowScroll={false}
        centered
        size="lg"
      >
        <div className={bannerStyle["header-container"]}>
          <MDBCloseIcon onClick={setForumModalOpen} />
        </div>
        <MDBModalBody>
          {auth.application?.carrierId === "NRIC" ? (
            <p style={{ textAlign: "center" }}>
              The North River Insurance Company announces a public forum review
              of the 2024 Short Term Medical Pivot Health Plan portfolio on
              November 4, 2024 at 2:00pm Central time. The virtual meeting will
              highlight the 2024 Pivot Health Short Term Medical plan
              performance, premiums and health care cost efforts. To register
              for this event,{" "}
              <a href={forumNricURL} target="_blank" rel="noopener noreferrer">
                click here
              </a>
              . Forum attendees may submit questions in advance to:{" "}
              <a href="mailto:forum2@healthcare.com">forum2@healthcare.com</a>.
            </p>
          ) : (
            <p style={{ textAlign: "center" }}>
              The Companion Life Insurance Company announces a public forum
              review of the 2024 Short Term Medical Pivot Health Plan portfolio
              on November 4, 2024 at 1:00pm Central time. The virtual meeting
              will highlight the 2024 Pivot Health Short Term Medical plan
              performance, premiums and health care cost efforts. To register
              for this event,{" "}
              <a href={forumClicURL} target="_blank" rel="noopener noreferrer">
                click here
              </a>
              . Forum attendees may submit questions in advance to:{" "}
              <a href="mailto:forum1@healthcare.com">forum1@healthcare.com</a>.
            </p>
          )}
        </MDBModalBody>
      </MDBModal>
      <AlertModal
        isOpen={isLogoutModalOpen}
        title={logoutText}
        body={logoutMessageText}
        positive={logoutText}
        negative={cancelText}
        onPositive={logout}
        onNegative={setLogoutModalOpen}
        onDismiss={setLogoutModalOpen}
      />
    </>
  );
};

export default Header;
